
main {
  padding: 1em;
  width: 1000px;
  margin: auto;
  max-width: calc(100% - 2em);
}

header {
  text-align: center;
  margin-bottom: 1em;
}

.today {
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  flex-direction: column;
}

.geometry {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  flex-direction: column;
}

.toolbarContainer {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1em;
  flex-wrap: wrap;
}

.toolbar > * {
  min-width: 300px;
}

.toolbar {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 1em;
  align-items: flex-start;
  padding-top: 0.5em;
}

.table {
  width: 800px;
  margin: auto;
  max-width: 100%;
}

.highlight {
  background-color: lightblue;
}

.spinner {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1em;
}